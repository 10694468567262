import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FaFacebook,FaDiscord,FaTwitter } from "react-icons/fa";
import classes from "./Footer.module.css";
import {NavLink} from 'react-router-dom';

const Footer = () => {
  return (
    <div className={classes.bg}>
        <div className={classes.row1}>
            <div className={classes.linkbox}><NavLink to="/privacy" className={classes.links}>Privacy Policy</NavLink></div>
        </div>
        {/*<Row className={classes.row1}>
            <Col sm={4}>
                <div className={classes.linkbox}><a href='#' className={classes.links}>About Us</a></div>
                <div className={classes.linkbox}><a href='#' className={classes.links}>Support Policy</a></div>
            </Col>
            <Col sm={4}>
                <div className={classes.linkbox}><a href='#' className={classes.links}>Contact Us</a></div>
                <div className={classes.linkbox}><NavLink to="/privacy" className={classes.links}>Privacy Policy</NavLink></div>
            </Col>
            <Col sm={4} className={classes.icons}>
                <FaFacebook className={classes.icon}></FaFacebook>
                <FaDiscord className={classes.icon}></FaDiscord>
                <FaTwitter className={classes.icon}></FaTwitter>
            </Col>
        </Row>*/}
        <Row>
            <Col xs={7} sm={9}><div className={classes.line}></div><div className={classes.copyright}>All rights reserved @ 2023</div></Col>
            <Col xs={5} sm={3}><img src='/FooterBrand.png' className={classes.brand}/></Col>
        </Row>
        
    </div>
  )
}

export default Footer