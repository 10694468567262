import React from 'react'
import classes from "./Home.module.css";
import Slider from './Slider';
import Video from './Video';

const Home = () => {
  return (
    <>
        <div className={classes.bg}>
          <Slider></Slider>
          <Video></Video>
        </div>
    </>
  )
}

export default Home