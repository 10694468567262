import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Footer from './Components/Footer';
import Home from './Components/Home';
import Navb from './Components/Navb';
import Privacy from './Components/Privacy';

function App() {
  return (
    <>
      <Router>
        <Navb></Navb>
        <Routes>
            <Route exact path='/' element={< Home />}></Route>
            <Route exact path='/privacy' element={< Privacy />}></Route>
        </Routes>
        <Footer></Footer>
      </Router>
    </>
  );
}

export default App;
