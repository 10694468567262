import React from 'react'
import {Nav, NavDropdown, Navbar, Container} from "react-bootstrap";
import {FaDiscord} from "react-icons/fa";
import classes from "./Navb.module.css";

const Navb = () => {
  return (
    <>
    <Navbar collapseOnSelect expand="lg" variant="dark">
      <Container className={classes.cont}>
        <Navbar.Brand href="#home">
            <img
              alt=""
              src="/Logo.png"
              className={classes.logo}
            />
            <img
              alt=""
              src="/Brand.png"
              className={classes.brand}
            />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
          <a href='https://discord.com/channels/1067269755142152203/1106115212786016296'><FaDiscord className={classes.icon}></FaDiscord></a>
          </Nav>
        </Navbar.Collapse>
        {/*<Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
          <Nav.Link href="#blog" className={classes.navItems}>Product</Nav.Link>
          <Nav.Link href="#blog" className={classes.navItems}>Pricing</Nav.Link>
            <NavDropdown title="Resources" className={classes.drop}>
              <NavDropdown.Item eventKey="Lead Generation and Acquisition" className={classes.dropItem}>Blog</NavDropdown.Item>
              <NavDropdown.Item eventKey="Social and Community Management" className={classes.dropItem}>Webinar</NavDropdown.Item>
              <NavDropdown.Item eventKey="Social Intelligence" className={classes.dropItem}>Knowledge Base</NavDropdown.Item>
              <NavDropdown.Item eventKey="Support and Social Inbox" className={classes.dropItem}>Press Room</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>*/}
      </Container>
    </Navbar>
    </>
  )
}

export default Navb