import React from 'react'
import classes from "./Privacy.module.css";
import { Row, Col, Container } from 'react-bootstrap';

const Privacy = () => {
  return (
    <div>
        <h1 className={classes.headText}>Privacy Policy</h1>
        <Container>
        <Row className={classes.row1}>
          <Col>GenZadda (<span className={classes.highlight}>”Company”</span> or <span className={classes.highlight}>“We”</span>) respect your privacy and are committed to protecting it through our compliance with this policy.</Col>
          <Col>This policy describes the types of information we may collect from you or that you may provide when you use our product or services (collectively referred to as “Services”) and our practices for collecting, using, maintaining, protecting, and disclosing that information.</Col>
          <Col>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Services.</Col>
          <Col>By using our Services, you agree to this privacy policy. This policy may change from time to time. Your continued use of our Services after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.</Col>
        </Row>

        <h1 className={classes.header}>Information we collect about you and how we collect it</h1>
        <Row className={classes.row2}>
          <Col>
            We collect information about you when you provide it to us, when you use our Services and when other sources provide it to us including information:
            <ul>
              <li>by which you may be personally identified, such as name, postal address, e-mail address or telephone number (<span className={classes.highlight}>”personal information”</span>);</li>
            </ul>
          </Col>
          <Col>
            <ul>
              <li>about your internet connection, the equipment you use to access our Services and usage details.</li>
            </ul>
            We collect this information: 
            <ul>
              <li>Directly from you when you input it into the Services or otherwise provide it to us.</li>
            </ul>
          </Col>
          <Col>
            <ul>
              <li>When you register for an account, modify your profile, set preferences, sign-up for or make purchases through the Services.</li>
              <li>As you provide Information through our support channels.</li>
            </ul>
          </Col>
          <Col>
            <ul>
            <li>As you collect payment and billing information when you register for certain paid Services.</li>
              <li>We keep track of certain information about you when you visit and interact with any of our Services.</li>
            </ul>
          </Col>
        </Row>


        <h1 className={classes.header}>Information you provide to us</h1>
        <Row className={classes.row1}>
          <Col sm={3}>The information we collect on or through our Services may include:</Col>
          <Col sm={3}>
            <ul>
              <li>Information that you provide by filling in forms in our Services. This includes information provided at the time of registering to use our Services or requesting a demo of the Company’s product.</li>
            </ul>
          </Col>
          <Col sm={3}>
            <ul>
              <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
            </ul>
          </Col>
        </Row>

        <h1 className={classes.header}>Information We Collect Through Automatic Data Collection</h1>
        <Row className={classes.row2}>
          <Col>
            As you navigate through and interact with our Services, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:
            <ul>
              <li>Details of your visits to our Services, including traffic data, and other communication data and the resources that you access and use on the Website.</li>
              <li>Information about your computer and internet connection, including your IP address, operating system, and browser type.</li>
            </ul>
          </Col>
          <Col>
            The information we collect automatically is statistical data and does not include personal information, but we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our Services and to deliver a better and more personalised service, including by enabling us to:
            <ul>
              <li>Estimate our audience size and usage patterns.</li>
              <li>Store information about your preferences, allowing us to customise our Services according to your individual interests.</li>
              <li>Speed up your searches.</li>
              <li>Recognize you when you return to our Services.</li>
            </ul>
          </Col>
          <Col>
            The technologies we use for this automatic data collection may include:
            <ul>
              <li><span className={classes.highlight}>Cookies (or browser cookies).</span> A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Services.</li>
              <li><span className={classes.highlight}>Flash Cookies.</span> Certain features of our Services may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Services. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see <a href='#'>Choices About How We Use and Disclose Your Information.</a></li>
            </ul>
          </Col>
          <Col>
            <ul>
              <li><span className={classes.highlight}>Web Beacons.</span> Pages of our the Services [and our e-mails] may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or [opened an email] and for other related Services statistics.</li>
            </ul>
          </Col>
        </Row>

        <h1 className={classes.header}>How we use your information</h1>
        <Row className={classes.row1}>
          <Col>
            We use information that we collect about you or that you provide to us, including any personal information:
            <ul>
              <li>To present our Services and their contents to you.</li>
            </ul>
          </Col>
          <Col>
            <ul>
              <li>To provide you with information, products, or services that you request from us.</li>
              <li>To fulfill any other purpose for which you provide it.</li>
              <li>To notify you about changes to our Services we offer or provide.In any other way we may describe when you provide the information.</li>
            </ul>
          </Col>
          <Col>
            <ul>
              <li>For any other purpose with your consent.</li>
            </ul>
          </Col>
          <Col>We may also use your information to contact you about our own Services that may be of interest to you.<br/><br/>We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.</Col>
        </Row>

        <h1 className={classes.header}>Disclosure of your information</h1>
        <Row className={classes.row2}>
          <Col>
            We may disclose personal information that we collect or you provide as described in this privacy policy:
            <ul>
              <li>To our subsidiaries and affiliates.</li>
              <li>To contractors, service providers, and other third parties we use to support our business.</li>
            </ul>
          </Col>
          <Col>
            <ul>
              <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganisation, dissolution, or other sale or transfer of some or all of Company’s assets, whether</li>
              <li>as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Company about our Website users is among the assets transferred.</li>
            </ul>
          </Col>
          <Col>
            <ul>
              <li>To fulfil the purpose for which you provide it.</li>
              <li>For any other purpose disclosed by us when you provide the information.</li>
              <li>With your consent.</li>
            </ul>
          </Col>
          <Col>
            We may also disclose your personal information:
            <ul>
              <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
              <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.</li>
            </ul>
          </Col>
        </Row>

        <h1 className={classes.header}>Choices about how we use and disclose your information</h1>
        <Row className={classes.row1}>
          <Col>We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:</Col>
          <Col>
            <ul>
              <li><span className={classes.highlight}>Tracking Technologies and Advertising.</span> You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe’s website. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</li>
            </ul>
          </Col>
          <Col>
            <ul>
              <li><span className={classes.highlight}>Promotional Offers from the Company.</span> If you do not wish to have your email address/contact information used by the Company to promote our own or third parties’ products or services, you can opt-out by checking the relevant box located on the form on which we collect your data or by sending us an email stating your request to connect@GenZadda.ai. If we have sent you a promotional email, you may send us a return email asking to be omitted from future email distributions.</li>
            </ul>
          </Col>
          <Col>
            <ul>
              <li>We do not control third parties’ collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (<span className={classes.highlight}>”NAI”</span>) on the NAI’s website.</li>
            </ul>
          </Col>
        </Row>

        <h1 className={classes.header}>Data Security</h1>
        <Row className={classes.row2}>
          <Col>We have implemented measures designed to secure your personal information from accidental loss and from unauthorised access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls.</Col>
          <Col>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Services, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</Col>
          <Col>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Services.</Col>
          <Col>Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained in the Services.</Col>
        </Row>

        <h1 className={classes.header}>Changes to our privacy policy</h1>
        <Row className={classes.row1}>
          <Col sm={3}>The Company reserves the right to change this policy at any time.  We will notify you of significant changes to our Privacy Policy by sending a notice to the primary email address specified in your account or by placing a prominent notice in our Services.</Col>
          <Col sm={3}>Significant changes will go into effect 30 days following such notification.  Non-material changes or clarifications will take effect immediately. You should periodically check the Services and this privacy page for updates.</Col>
          <Col sm={3}><span className={classes.highlight}>Contact Information</span><br/><br/>To ask questions or comment about this privacy policy and our privacy practices, contact us at: connect@GenZadda.ai</Col>
        </Row>
        </Container>

    </div>
  )
}

export default Privacy