import React from 'react'
import classes from "./Video.module.css";
import { Container } from 'react-bootstrap';

const Video = () => {
  return (
    <div className={classes.bg}>
        <Container>
        <div className={classes.myVideo}>
            <iframe src="https://www.youtube.com/embed/hwtvmoSfQk8"
            frameborder='0'
            allow='autoplay; encrypted-media'
            allowfullscreen
            title='video'
        />
        </div>
        </Container>
    </div>
  )
}

export default Video