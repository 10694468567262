import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import classes from "./Slider.module.css";
import slider from './Slider/Slider.png';
import pic1 from './Slider/Pic1.png';
import pic2 from './Slider/Pic2.png';
import pic3 from './Slider/Pic31.png';
import pic4 from './Slider/Pic4.png';
import pic5 from './Slider/Pic5.png';
import { Col, Row } from 'react-bootstrap'


const Slider = () => {
    return (
        <div className={classes.myCarousel}>
        <Carousel controls={false}>
          <Carousel.Item>
            <img
              className="d-block w-100 img"
              src={slider}
              alt="First slide"
            />
            <Carousel.Caption>
              <h3 className={classes.headText}>Unleash Your Moderation Superpowers with Ease!</h3>
              <Row>
                <Col sm={6}>
                  <img
                  src={pic1}
                  alt="First slide"
                  className={classes.pic}/>
                </Col>
                <Col sm={6}>
                  <p className={classes.carouselText}>Empower your community management with effortless AI superpowers. From intelligent content filtering to streamlined user management, unleash your moderation abilities and create a thriving, inclusive virtual world with ease.</p>
                  {/*<a href="#" className={classes.btn}>Learn more</a>*/}
                </Col>
              </Row>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 img"
              src={slider}
              alt="Second slide"
            />
            <Carousel.Caption>
              <h3 className={classes.headText}>Seamless data Yoda: Precision with style</h3>
              <Row>
                <Col sm={6}>
                  <img
                  src={pic2}
                  alt="First slide"
                  className={classes.pic}/>
                </Col>
                <Col sm={6}>
                  <p className={classes.carouselText}>Say goodbye to complex data analytics. Our product makes it as effortless as a no-scope headshot, leverages AI, delivering comprehensive insights and reporting for your virtual community. Unleash the power of data with ease and precision.</p>
                  {/*<a href="#" className={classes.btn}>Learn more</a>*/}
                </Col>
              </Row>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 img"
              src={slider}
              alt="Third slide"
            />
            <Carousel.Caption>
              <h3 className={classes.headText}>Ignite Your Tribe, Unleash the Energy!</h3>
              <Row>
                <Col sm={6}>
                  <img
                  src={pic3}
                  alt="First slide"
                  className={classes.pic}/>
                </Col>
                <Col sm={6}>
                  <p className={classes.carouselText}>Ignite your tribe, fuel engagement, and unleash electrifying energy within your virtual community.Use AI generated content and Say goodbye to dull moments and hello to an electrifying atmosphere that keeps your members buzzing with anticipation.</p>
                  {/*<a href="#" className={classes.btn}>Learn more</a>*/}
                </Col>
              </Row>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 img"
              src={slider}
              alt="Third slide"
            />
            <Carousel.Caption>
              <h3 className={classes.headText}>Turbocharge community growth: Patience is overrated!</h3>
              <Row>
                <Col sm={6}>
                  <img
                  src={pic4}
                  alt="First slide"
                  className={classes.pic}/>
                </Col>
                <Col sm={6}>
                  <p className={classes.carouselText}>Leave patience behind and turbocharge your community's growth. Our product empowers you to break free from limitations, fueling rapid expansion, and propelling your virtual community to new heights of success. Unleash the potential and accelerate your community's growth today!</p>
                  {/*<a href="#" className={classes.btn}>Learn more</a>*/}
                </Col>
              </Row>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 img"
              src={slider}
              alt="Third slide"
            />
            <Carousel.Caption>
              <h3 className={classes.headText}>Groovy insights that make campaigns rock and roll!</h3>
              <Row>
                <Col sm={6}>
                  <img
                  src={pic5}
                  alt="First slide"
                  className={classes.pic}/>
                </Col>
                <Col sm={6}>
                  <p className={classes.carouselText}>Our product delivers campaign management that hits all the right notes, providing you with valuable data and strategies to make your virtual community campaigns truly shine. Unleash the power of Artificial Intelligence and watch your campaigns reach new heights.</p>
                  {/*<a href="#" className={classes.btn}>Learn more</a>*/}
                </Col>
              </Row>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        </div>
      );
}

export default Slider